import React, { useEffect, useState } from 'react';
import style from './replica.module.scss';
import { Row, Col, Container, Navbar } from 'react-bootstrap';
import { IMG_URL } from '../../../configuration/apiconfig';


function Banner(props) {

    return (

        <section id="home" className="banner_section" style={{ backgroundImage: `url(${props.data.banner_data.banner})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <div className="container">
                <div className="banner_contant_sec">
                    <h3 style={{ color: 'white' }}>{props.data.header_data && props.data.header_data.content?.home_title1}</h3>
                    <h1 style={{ color: 'white' }}>{props.data.header_data && props.data.header_data.content?.home_title2} </h1>
                    <a target="_blank" className="banner_button" href={props.data.header_data.regsitration_url}>JOIN US</a>
                </div>
            </div>
        </section>

    )
}

export default Banner


