// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "https://demo5.iossmlm.com/mlm_12.8/backoffice/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "https://demo5.iossmlm.com/mlm_12.8/uploads/images/";
// const BASE = "https://demo5.iossmlm.com/mlm_12.8";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE
// }

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://checkout.demo5.iossmlm.com/backoffice/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "http://checkout.demo5.iossmlm.com/uploads/images/";
// const BASE = "http://checkout.demo5.iossmlm.com/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE
// }


// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://159.89.225.72:1000/backoffice/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "http://159.89.225.72:1000/uploads/images/";
// const BASE = "http://159.89.225.72:1000";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE
// }
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "https://backoffice.infinitemlmsoftware.com/backoffice/api/";
// const IMG_URL = "https://backoffice.infinitemlmsoftware.com/uploads/images/";
// const BASE = "https://backoffice.infinitemlmsoftware.com";
// const API_KEY =localStorage.getItem('apiKey');
// const DEFAULT_KEY = '14963'
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE, 
//    DEFAULT_KEY

// }

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://demo3.infinitemlmdemo.com/arno_schnee/backoffice/api/";
// const IMG_URL = "http://demo3.infinitemlmdemo.com/arno_schnee/uploads/images/";
// const BASE = "http://demo3.infinitemlmdemo.com/arno_schnee";
// const API_KEY =localStorage.getItem('apiKey');
// const DEFAULT_KEY = 'e4913264-0a59-477a-87d9-e3b74812b00d'
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE, 
//    DEFAULT_KEY

// }
import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
const BASE_URL = "https://con-tacte.net/backoffice/api/";
const IMG_URL = "https://con-tacte.net/uploads/images/";
const BASE = "https://con-tacte.net";
const API_KEY =localStorage.getItem('apiKey');
const DEFAULT_KEY = 'e4913264-0a59-477a-87d9-e3b74812b00d'
export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE, 
   DEFAULT_KEY

}



// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://mlmadmin.demo5.iossmlm.com/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "http://mlmadmin.demo5.iossmlm.com/uploads/images/";
// const BASE = "http://mlmadmin.demo5.iossmlm.com";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY ='14869ac2-d19b-497b-b0bb-4845393e11f9'
// console.log(localStorage);
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.7.74/garage/work-copy/mlm/backoffice/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "http://192.168.7.74/garage/work-copy/mlm/uploads/images/";
// const BASE = "http://192.168.7.74/garage/work-copy/mlm/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY = 'bfd6eb01-9991-4380-88bc-c42852a8dbcc'
// //const DEFAULT_KEY = '14963'
// console.log(localStorage);
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }

