import React from 'react';
//css
// import style from './../common/Common.module.scss';
import style from './crm.module.scss';
import {Form} from 'react-bootstrap';
import {DatePickers,FormInputControl,DropDownBs, CustomButton} from '../common';
//service
import {useTranslation} from 'react-i18next';

// const stylees = {
//     "z-index" : "1000 !important"
// }

function Filter(props) {

    console.log("-----",props)
    const {t}  = useTranslation();
    const interest = [
        {
            id : 'All'
        },
        {
            id : 'Very Interested'
        },
        {
            id : 'Interested'
        },
        {
            id : 'Not That Interested'
        }
    ];
    const leadStatus = [
        {
            id : 'All'
        },
        {
            id : 'Ongoing'
        },
        {
            id : 'Accepted'
        },
        {
            id : 'Rejected'
        },

    ];

    //date change handler 
    const dateChange= (date,id) =>{
        props.datechange(date,id,'FormData');
    }
    return (
                <div className={`${style.panel} ${style.papanel_defaultnel}`}>
                    <div className={`${style.panel_body}`}>  
                    <legend><span className={`${style.fieldset_legend}`}>{props.title}</span></legend>
                        <div>
                        <Form  onSubmit={props.onSubmitForm} >
                            <FormInputControl 
                                label={t('Common.searchTag')}
                                inputProps={{
                                    type : 'text',
                                    name : 'user_name',
                                    placeholder:t('Crm.searchTag'),
                                    value : props.FormData.search,
                                    onChange: (e)=>props.chnageHandler(e,'FormData')
                                }}
                                error={props.FormData.formError.search}
                            />
                            <DatePickers
                             label={t('Crm.LeadAddedFromDate')}
                             className={style.datePicker}
                             start={props.FormData.followup_added_date_from}
                             placeholderText={t('Common.fromDate')}
                             datechange={dateChange}
                             id="followup_added_date_from"
                            />
                            <DatePickers
                             label={t('Crm.LeadAddedToDate')}
                             className={style.datePicker}
                             start={props.FormData.followup_added_date_to}
                             placeholderText={t('Common.toDate')}
                             datechange={dateChange}
                             id="followup_added_date_to"
                            />
                            <DatePickers
                             label={t('Crm.NextFollowUpFromDate')}
                             className={style.datePicker}
                             start={props.FormData.followup_date_from}
                             placeholderText={t('Common.fromDate')}
                             datechange={dateChange}
                             id="followup_date_from"
                            />
                            <DatePickers
                             label={t('Crm.NextFollowUpToDate')}
                             className={style.datePicker}
                             start={props.FormData.followup_date_to}
                             placeholderText={t('Common.toDate')}
                             datechange={dateChange}
                             id="followup_date_to"
                            />
                            <DropDownBs 
                            label={t('Crm.levelOfInterest')}
                            options={{
                                data : interest,
                                value : 'id',
                                label : 'id'
                            }}
                            DropdownProps={{
                                name:"interest_status",
                                onChange:(e)=>props.chnageHandler(e,'FormData'),
                                //value:'All'
                            }}
                            />

                            <DropDownBs 
                            label={t('profile.country')}
                            preOption={t('profile.country')}
                            options={{
                                data : props.country,
                                value : 'value',
                                label : 'title'
                            }}
                            DropdownProps={{
                                name:"country",
                                onChange:(e)=>props.chnageHandler(e,'FormData'),
                            }}
                            />


                            <DropDownBs 
                            label={t('Crm.leadStatus')}
                            options={{
                                data :leadStatus,
                                value : 'id',
                                label : 'id'
                            }}
                            DropdownProps={{
                                name:"lead_status",
                                onChange:(e)=>props.chnageHandler(e,'FormData'),
                                // value:'All'
                             }}
                            />


                            <DatePickers
                             label={t('Crm.LeadStatusChangeFromDate')}
                             start={props.FormData.status_date_from}
                             placeholderText={t('Common.fromDate')}
                             datechange={dateChange}
                             id="status_date_from"
                            />

                            <DatePickers
                             label={t('Crm.LeadStatusChangeToDate')}
                             start={props.FormData.status_date_to}
                             placeholderText={t('Common.toDate')}
                             datechange={dateChange}
                             id="status_date_to"
                            />
                            <CustomButton
                                type="submit"
                                variant="primary"
                            >
                                  {props.buttonloader ?
                            <i className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                            /> : ''}
                                {t('Button.submit')}
                            </CustomButton>
                        </Form> 
                        </div>
                    </div>
                </div>
            );
}
export default Filter

