
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'
import companyName from '../../../../store/mobxStore/companyName'
import Forms from '../../../components/auth/Forms'
import ForgotPasswordService from '../../../../service/Auth/ForgotPassowrd';
import ForgotButton from '../../../components/auth/ForgotButton'
import { BASE } from '../../../../configuration/apiconfig';
import { Alert } from 'react-bootstrap';




function ForgotPassword({ t }) {


    var captchvalue;

    const [state, setState] = useState({
        userName: '',
        Email: '',
        captcha: '',
        captchvalue: '',
        isLoggedError: false,
        FormError: {
            userName: "",
            Email: "",
            captcha: ""
        },
        btnDisabled: false
    })
    const [show, setShow] = useState(false);

    const formValid = formError => {
        let valid = true;
        Object.values(formError).forEach(
            val => val.length > 0 && (valid = false)
        );
        return valid;
    }

    onchange = (e) => {
        const service = new ForgotPasswordService();
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name === 'userName') {
            const formData = {
                username: value
            }
            service.checkUsername(formData).then(res => {
                if (!res.status) {
                    if (res.error.code == '1011') {
                        setState(prevState => ({
                            ...prevState,
                            FormError: {
                                ...prevState.FormError,
                                userName: 'This username does not exist'

                            }
                        }));


                    }

                }


            })
        }

    }


    const requiredValid = (value, name) => {
        const FormError = state.FormError;
        switch (name) {
            case 'userName':
                FormError.userName = value.length === 0 ? "unamereq" : "";
                break;
            case 'Email':
                FormError.Email = value.length === 0 ? "emailreq" : "";
                break;
            case 'captcha':
                FormError.captcha = value.length === 0 ? "captchareq" : "";
                break;
            default:
                break;
        }

        setState(prevState => ({
            ...prevState,
            FormError
        }));

    }

    const onSubmit = async e => {
        e.preventDefault();//prevent default  
        requiredValid(state.userName, 'userName');
        requiredValid(state.Email, 'Email');
        requiredValid(state.captcha, 'captcha');
        if (formValid(state.FormError)) {
            setShow(true)
        }


    }
    const getRandom = () => {
        captchvalue = BASE + '/backoffice/captcha/load_captcha/admin/' + Math.random();
        setState(prevState => ({
            ...prevState,
            captchvalue: BASE + '/backoffice/captcha/load_captcha/admin/' + Math.random()
        }));

    }

    useEffect(() => {
        getRandom();


    }, [captchvalue])

    return (



        <>
            <div className="mb-2">
                <Helmet>
                    <title>{companyName.companyName} | {t('Login.forgotPassword')}</title>
                </Helmet>

                {show &&
                    <Alert dismissible variant="warning" onClose={() => setShow(false)} dismissible >
                        <span style={{ color: 'green' }}>This feature is not available in DEMO</span>


                    </Alert>
                }
                <form id="forgot-form">
                    <Forms
                        type="text"
                        name="userName"
                        change={onchange}
                        error={state.FormError.userName}
                    />
                    <Forms
                        type="email"
                        name="Email"
                        change={onchange}
                        error={state.FormError.Email}
                    />
                    <div style={{ textAlign: 'center' }}>
                        <img src={state.captchvalue} style={{ verticalAlign: "middle" }} alt="No Image" width={100} height={100} />

                        <a style={{ verticalAlign: "middle", fontSize: '15px', float: 'right', cursor:'pointer' }} onClick={getRandom}>   {t('Common.changeCaptcha')} </a>
                     

                    </div>


                    <Forms
                        type="text"
                        name="captcha"
                        change={onchange}
                        error={state.FormError.captcha}
                    />
                    <ForgotButton
                        submit={onSubmit}

                    />

                </form>
            </div>

        </>
    )

}
export default withTranslation()(ForgotPassword);