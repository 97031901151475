import React from 'react';
import {Card} from 'react-bootstrap';
import style from '../tools.module.scss';
import {useTranslation} from 'react-i18next';
import { IMG_URL } from '../../../../configuration/apiconfig';
import { CustomButton } from '../../common';

function SocialInvite(props) {
    const {t} = useTranslation();
    const shareClick = (data,type) =>{
        let sharerURL = '';
        let fburl =''
        if(type === 'fb'){
            sharerURL = "http://www.facebook.com/sharer/sharer.php?s=100&p[url]=" + encodeURI(fburl) + "&p[title]=" + encodeURI(data.subject) + "&p[summary]=" + encodeURI(data.content);
        }else if(type === 'insta'){
            sharerURL = "http://instagram.com/home?status=" + encodeURI(data.content);
        }else if(type === 'twitter'){
            sharerURL = "http://twitter.com/share?text=" + encodeURI(data.content);
        }
        window.open(
            sharerURL,
            '',
            'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0'
        )
    }
    return (
        <Card>
            <Card.Body className={style.cardBody}>
                {
                    Object.entries((props.data)).map((key,value)=>{
                        return <React.Fragment key={value}>
                            <legend>
                                <span>{t(`Common.${key[0]}`)}</span>
                            </legend>
                            {
                                key[1].map((item,index)=>{
                                    return <div className={style.row} key={index}>
                                                <div className={style.socialItem}>
                                                    <div className={style.user}>
                                                        <img src={`${IMG_URL}logos/${key[0]}.png`} alt=""/>
                                                    </div>
                                                    <div className={style.socialContent}>
                                                        <h3>{item.subject}</h3>
                                                        <span>{t(`Common.${item.type}`)}</span>
                                                        <div className={style.text}>
                                                            <p></p>
                                                            <p dangerouslySetInnerHTML={{__html:item.content}}></p>
                                                            <p></p>
                                                        </div>
                                                        <div className={style.shareBtn}>
                                                            <CustomButton
                                                                variant="info"
                                                                onClick={()=>shareClick(item,key[0])}
                                                            >
                                                                <i className="fa fa-share-alt"></i>
                                                            </CustomButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                })
                            }
                        </React.Fragment>
                    })
                }
            </Card.Body>
        </Card>
    )
}

export default SocialInvite
