import React,{useState,useEffect} from 'react';
import style from './profile.module.scss';
import {FormHead,FormsControl,Buttons} from './';
import {useSelector,useDispatch}  from 'react-redux';
import ProfileService from '../../../service/profile';
import {AlertBs} from '../common';
import {changeLang,changeCurr} from '../../../store/action';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
function Settings(props) {
    const [data, setData] = useState(props.data);
    const {t} = useTranslation();
    const history = useHistory();
    const [alertData,setAlertData] = useState({
        show : false,
        message : '',
        varient : ''
    });
    const Lang      = useSelector(state => state.Lang);
    const DefaultCurrency      = useSelector(state => state.curr);
    const dispatch  = useDispatch();
    const {i18n}    = useTranslation();
    //lifecycle 
    useEffect(()=>{
        let newData= props.data.map(object=>{
            if(object.code === 'binaryLegSettings'){
                let newOption= object.options.map((option)=>{
                    return{
                        ...option,
                        code : t('profile.'+option.code)
                    }
                })
                return{
                    ...object,
                    options : newOption
                }
            }else if(object.code === 'language'){
                return{
                    ...object,
                    value : Lang.currentLang
                }
            }else if(object.code === "currency"){
                return{
                    ...object,
                    value : DefaultCurrency.id
                }
            }else{
                return object
            }
        })
        setData(newData)
    },[props.data,Lang.currentLang,DefaultCurrency.id,t])

    const changeHandler = (e,isReq) =>{
        const  { name , value} = e.target;
        setData([...data].map(object => {
            if(object.code === name) {
              return {
                ...object,
                value: value,
                error : value.length===0 && isReq?name:''
              }
            }
            else return object;
          }))
    }
    const cancelButtonClick = () =>{
        setData(props.data)
        props.cancelbtn();
    }
    const onSubmit = () =>{
        let error = false
        let formdata={};
        let newLang = '';
        let newCurrency = '';
        data.forEach(element => {
            if(element.error){
                error = true
            }else{
                formdata ={
                    ...formdata,
                    [element.code] : element.value 
                }
            }
            if(element.code === 'language'){
               newLang=element.value
            }else if(element.code === 'currency'){
                element.options.every(option => {
                    if(option.value === element.value){
                        newCurrency = option;
                        return false;
                    }
                    return true
                });
            }
        });
        if(!error){
            const service = new ProfileService();
            service.UpdateSettings(formdata).then((res)=>{
                if(res.status){
                    if(res.data.status){
                        props.getData();
                        dispatch(changeLang(newLang));
                        i18n.changeLanguage(newLang);
                        dispatch(changeCurr(newCurrency.symbol_left,newCurrency.currency_value,newCurrency.precision,newCurrency.value))
                        props.cancelbtn();
                        props.updateSuccess(data,props.stateKey);
                        props.showNotify('success','success','profile.'+res.data.message)
                    }
                }else{
                    if(res.error.code === 1004){
                        Object.keys(res.error.fields).map((key)=>{
                            setData([...data].map(object => {
                                if(object.code === key) {
                                  return {
                                    ...object,
                                    error : 'validation.'+res.error.fields[key],
                                    errorField : {
                                        field : key
                                    }
                                  }
                                }
                                else return object;
                              }))
                              return null
                        })
                        setAlertData({
                            show : true,
                            message : 'validation.checkValueYouHaveSubmitted',
                            varient : 'danger'
                        })
                    }else if(res.error.code === 1002){
                        history.push('/logout')
                    }
                }
            })
        }
    }
    //onclose alert message box
    const closeAlert = () =>{
        // setShoeAlert(false)
        setAlertData({
            show    : false,
            message : '',
            varient : ''
        })
    }
    //change the dropdown items
    const changeDropDown = (key,id) =>{
        setData([...data].map(object => {
            if(object.code === id.code) {
                return {
                ...object,
                value : key
                }
            }
            else return object;
        }));
    }
    return (
        <div className={style.Settings}>
            <AlertBs 
                varient={alertData.varient}
                show={alertData.show}
                message={alertData.message}
                close={closeAlert}
            />
            <FormHead 
                title='settings' 
                change={props.change} 
                status={props.status}
                show={props.isEditable}
            />
            {
                data.map((forms,index)=>{
                    return <FormsControl 
                                key={index}
                                change={changeHandler}
                                status={props.isEditable}
                                data={forms}
                                dropdownChange={changeDropDown}
                            />
                })
            }
            {
                !props.isEditable &&
                <Buttons 
                    cancel={cancelButtonClick}
                    submitHandler={onSubmit}
                />
            }    
        </div>
    )
}

export default Settings
