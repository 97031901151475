import React, { useState, useEffect } from 'react'
import style from './profile.module.scss';
import ProfileService from '../../../service/profile';
import { useHistory } from 'react-router-dom';
import AppInfoservice from '../../../service/common/Appinfo';
import { useTranslation } from 'react-i18next';




function ProfileImage(props) {

  const [url, setUrl] = useState();
  const history = useHistory();
  const { t } = useTranslation();



  const serviceApp = new AppInfoservice();

  const onFileChange = event => {


    // Update the state
    if (event.target.files[0]) {

      let formdata = new FormData();
      let service = new ProfileService();
      formdata.append('image', event.target.files[0])
      service.uploadImage(formdata).then(res => {
    
        if (res.status) {
          setUrl(URL.createObjectURL(event.target.files[0]))
          props.notify('success', 'Common.success', 'Common.profileUpdatedSuccesfully');
          setTimeout(() => {
            props.getData();
          }, 1000);
        } else {
          if (res.error.code === 1038) {
            props.notify('error', 'Common.error', 'validation.tooManyUploadLimit');
          } else if (res.error.code === 1017) {
            props.notify('error', 'Common.error', 'validation.fileTypeNotSupported');
          } else if (res.error.code === 1018) {
            props.notify('error', 'Common.error', 'validaion.fileTypeExceeded');
          } else if (res.error.code === 1024) {
            props.notify('error', 'Common.error', 'Common.error_file_upload');
          } else if (res.error.code === 1002) {
            history.push('/logout');
          } else if (res.error.code === 1032) {
            props.notify('error', 'Common.error', 'validaion.chooseFile')
          }
        }
      })
    }

  };
  useEffect(() => {
    setUrl(props.image);

  }, [props])



  const onDeletePic = event => {

    if (window.confirm(t('validation.sureWantToDelete'))) {
      let service = new ProfileService();

      service.deleteImage().then(res => {

        if (res.status) {
          props.getData();

          props.notify('success', 'Common.success', 'Common.profileDeletedSuccesfully');

        } else if (res.status === 404) {


          props.notify('error', 'Common.success', 'Common.cantdeletedefaultpic');

        }

      })

    }

  };







  return (


    <div className={style.avatarImage}>
      <div className={style.avatarEdit}>
        <input type="file" id="imageUpload" accept=".png,.jpg,.jpeg" onChange={onFileChange} />
        <label htmlFor="imageUpload" >
        </label>
      </div>
      <div className={style.avatarPreview}>
        <img src={url} alt="" />

      </div>
      <button style={{
        color: "red", top: "80px",
        left: "18px",
        position: "absolute",
        background: "#201e1ed4", cursor: "pointer", borderRadius: "100%", width: "34px", height: "34px"
      }} onClick={onDeletePic}><i className="fa fa-trash-o" aria-hidden="true"></i></button>
    </div>
  )
}

export default  ProfileImage ;
