import React ,{useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import style from './ewallet.module.scss';
import {CurrencyDecimalFormat} from '../../helper';
function Tiles(props) {

  
    const {t} = useTranslation();
    const [data,setData] = useState([]);
    const Currency       = useSelector(state => state.curr);
    useEffect(()=>{
        let ewalletTiles = props.tilesData.map((items)=>{
            var background ;
            var img;
            console.log(items.text)

            switch(items.text){
                
                case 'credited' :
                    background = style.green;
                    img = 'income-w.png';
                    break;
                case 'debited':
                    background = style.red;
                    img = 'Bonus-w.png';
                    break;
                case 'ewalletBalance':
                    img = "E-Wallet-w.png";
                    background = style.violet;
                    break;
                case 'purchaseWallet':
                    img = 'income-w.png';
                    background = style.pink;
                    break;
                case 'commissionEarned':
                    img = 'income-w.png';
                    background = style.blue;
                    break;
                default:
                    background = style.blue;
                    img = 'income-w.png'; 
            }
            // background = style.blue
            return{
                amount : items.amount,
                text   : items.text,
                background  : background,
                img : img
            }
        });
        setData(ewalletTiles);
    },[setData,props.tilesData])
    const removeData =(data)=>{
        let array =[]
        for(let i=0;i<data.length;i++){
            if(props.repurchase_status=='yes'){
                array.push(data[i])
            }
            else if(props.repurchase_status=='no' && data[i].text!='purchaseWallet'){
                array.push(data[i])
            }
            else{
               // array.push(data[i])
            }

     

        }
        return array;
        // if(props.repurchase_status=='no'){
        // array = data.filter((obj)=> return obj.text!='purchaseWallet'
        // }
           
      
    }


    return (
        <div className={`${style.ewalletTiles}`}>
            {
                removeData(data).map((tiles,index)=>{     
                    
                   

                    return <div className={`${style.Tiles} d-flex`} key={index} >
                                <div className={`${style.leftIcon} ${tiles.background}`}>
                                    <img src={require('../../../assets/images/logos/'+tiles.img).default} alt=""/>
                                </div>
                                <div className={style.tilesData}>
                                    <h4>{t('Common.'+tiles.text)}</h4>
                                    <span className={style.TilesAmount} title={(tiles.amount*Currency.value).toFixed(Currency.precision)}>{Currency.currentCurr} {CurrencyDecimalFormat(tiles.amount*Currency.value,Currency.precision)}</span>
                                </div>
                            </div> 
                 
                 })
            }
        </div>
    )
}

export default Tiles
