
import React, { useEffect } from 'react';
import style from './dashboard.module.scss';
import wallet from '../../../assets/images/dashboard/wallet-icon.png';
import { useHistory } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {CurrencyDecimalFormat} from '../../helper';
import commission from '../../../assets/images/dashboard/commision-earned.png';
import payment from '../../../assets/images/dashboard/paymnt-rlsd.png';
import pending  from '../../../assets/images/dashboard/pending-5-861794.png';
import UserStore from '../../../store/UserStore';
import TilesFilter from './TilesFilter';



const  TileItems = React.memo((props)=>{
    console.log("TileItems",props)
    const history = useHistory();
    const {t} = useTranslation();
    const curr = useSelector(state => state.curr);


   // const FixedAmount = (props.amount * curr.value).toFixed(curr.precision)
    let amount = CurrencyDecimalFormat(props.amount * curr.value,curr.precision);
    const boxnameclass= props.boxname === 0 ? style.lst_box1 : props.boxname === 1 ? style.lst_box2 : props.boxname === 2 ? style.lst_box3 :style.lst_box4 ;
    const images = props.boxname === 0 ? wallet : props.boxname === 1 ? commission : props.boxname === 2 ? payment :pending ;

    const dashboardtileHandler = (e) =>{
        e.preventDefault()
        if (props.text === 'commision') {
            UserStore.checkUserEarnings = true    
        }
        history.push(props.to);
    }
    const FiterData=[
        {
            code : 'all',
            value : ''
        },
        {
            code : 'thisYear',
            value : ''
        },
        {
            code : 'thisMonth',
            value : ''
        },
        {
            code : 'thisWeek',
            value : ''
        }
    ]
    const filterChange = (key) =>{
        props.filterChange(props.text,key)
    }

return (
   <>
    <div className={style.list_boxes_main} >
        <div className={`${style.dashboard_pannel_box} ${boxnameclass}`}>
            {   props.filter &&
                 <TilesFilter 
                 items={FiterData}
                 onChange={filterChange}
                 />
            }
            <div className={style.listing_box_sec} onClick={dashboardtileHandler}>
                <div className={style.listing_box_ico_rnd}><img src={images} alt="" /></div>
                <div className={style.listing_box_head}>{t('Tails.'+props.text)}</div>
                {/* <div className={style.listing_box_cnout}>{curr.currentCurr} {amount}</div> */}
                  <div className={style.listing_box_cnout}>{props.amount_withcurrency}</div>

            </div>
        </div>
    </div>

    </>


)



})
export default TileItems;