import React,{useState} from 'react';
import {FormHead,FormsControl,Buttons} from './';
import style from './profile.module.scss';
import ProfileService from '../../../service/profile';
import {useHistory} from 'react-router-dom';
import { PasswordValidation } from '../../hook/passwordValidation';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
function BankDetails(props) {
  
    const [data, setData] = useState(props.data);
    const history = useHistory();
    const changeHandler = (e,isReq) =>{
        const  { name , value} = e.target;
        setData([...data].map(object => {
            if(object.code === name) {
                if(name=='accountHolder'|| name=='bankName' || name=='branchName' || name=='pan'){
                    return {
                        ...object,
                        value: value,
                        error : value.length<3 && 'validation.min_length',
                        errorField : {
                            field : name,
                            length:3
                        }

                      }
                }
              return {
                ...object,
                value: value,
                error : value.length===0 && isReq?name:''
              }
            }
            else return object;
          }))
    }
    const cancelButtonClick = () =>{
        setData(props.data)
        props.cancelbtn();
    }
    const onSubmit = () =>{
        let error = false
        let formdata={};
        data.forEach(element => {
            if(element.error){
                error = true
            }else{
                formdata ={
                    ...formdata,
                    [element.code] : element.value 
                }
            }
        });
        if(!error){
            const service = new ProfileService();
            service.UpdateBankDetails(formdata).then((res)=>{
                if(res.status){
                    if(res.data.status){
                        props.getData();
                        props.cancelbtn();
                        props.updateSuccess(data,props.stateKey);
                        props.showNotify('success','success','profile.'+res.data.message)
                    }
                }else{
                    if(res.error.code === 1004){
                        Object.keys(res.error.fields).map((key)=>{
                            setData([...data].map(object => {
                                if(object.code === key) {
                              
                                  return {
                                    ...object,
                                    error : 'validation.'+res.error.fields[key]+'_err',
                                    errorField : {
                                        ...object,
                                        field : key,
                                        length : res.error.fields[key+'_err']==='min_length'?3:32

                                    }
                                  }
                                }
                                else return object;
                              }))
                              return null
                        })
                        props.showNotify('danger','error','validation.checkValueYouHaveSubmitted')
                    }else if(res.error.code === 1002){
                        history.push('/logout')
                    }
                }
            
                // props.cancelbtn();
            })
        }
    }
    return (

        
        <div className={style.BankDetials}>
            <FormHead 
                title='bankDetails' 
                change={props.change} 
                status={props.status}
                show={props.isEditable}
            />
            {
                data.map((forms,index)=>{
                    return <FormsControl 
                                key={index}
                                change={changeHandler}
                                status={props.isEditable}
                                data={forms}
                            />
                })
            }
            {
                !props.isEditable &&
                <Buttons 
                    cancel={cancelButtonClick}
                    submitHandler={onSubmit}
                />
            }
        </div>
    )
}

export default BankDetails
