const { BASE_URL,DEFAULT_KEY } = require("../../configuration/apiconfig");

export default class ForgotPasswordService {
    async checkUsername(formData){
        const response = await fetch(BASE_URL+'common/check_username', {     //url
            method: 'POST',                 //method
            headers : {
                'Content-Type'  : 'application/json',
                'api-key'       : 'DEFAULT_KEY'
            },
            body : JSON.stringify(formData)
        });
        const res = await response.json();
        return res;
    }
}